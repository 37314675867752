import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { showRouterFallback } from "./fallback/Default";
import Member from './page/Member'

import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// import Samplepage from './page/Samplepage';

// Paging
const Absen = React.lazy(() => import("./page/Absen"));
const PointChangePage = React.lazy(() => import("./page/PointChangePage"));
const Dashboard = React.lazy(() => import("./page/TabDashboard"));
const Login = React.lazy(() => import("./page/Login"));
// const ExampleProduct = React.lazy(() => import("./page/ExampleProduct"));
const MutasiPoint = React.lazy(() => import("./page/MutasiPoint"));
const Grosir = React.lazy(() => import("./page/Grosir"));
const Salesman = React.lazy(() => import("./page/Salesman"));
// const Member = React.lazy(() => import("./page/Member"));
const Hadiah = React.lazy(() => import("./page/Hadiah"));
const Booking = React.lazy(() => import("./page/Booking"));
const Transaksi = React.lazy(() => import("./page/Transaksi"));
const Penukaran = React.lazy(() => import("./page/Penukaran"));
const Product = React.lazy(() => import("./page/Product"));
const Rules = React.lazy(() => import("./page/Rules"));
const CodeGrandPrize = React.lazy(() => import("./page/CodeGrandPrize"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: [],
    };
  }

  render() {
    return (
      <div className="app">
        <Router>
          <Suspense fallback={showRouterFallback()}>
            <Switch>
              <Route exact path="/salesman">
                <Salesman />
              </Route>

              {/* Absen */}
              <Route exact path="/absen">
                <Absen baseContext={this} />
              </Route>

              {/* Point exchange */}
              <Route exact path="/point-exchange">
                <PointChangePage baseContext={this} />
              </Route>

              {/* Grosir */}
              <Route exact path="/grosir">
                <Grosir baseContext={this} />
              </Route>

              {/* Dashboard */}
              <Route exact path="/dashboard">
                <Dashboard baseContext={this} />
              </Route>

              {/* Login */}
              <Route exact path="/admin">
                <Login baseContext={this} />
              </Route>

              {/* Mutasi Point Mutation */}
              <Route exact path="/mutasi-point">
                <MutasiPoint baseContext={this} />
              </Route>

              {/* Member*/}
              <Route exact path="/member">
                <Member baseContext={this} />
              </Route>

              {/* Hadiah */}
              <Route exact path="/hadiah">
                <Hadiah baseContext={this} />
              </Route>

              {/* Booking */}
              <Route exact path="/booking">
                <Booking baseContext={this} />
              </Route>

              {/* Transaksi */}
              <Route exact path="/transaksi">
                <Transaksi baseContext={this} />
              </Route>

              {/* Exchange */}
              <Route exact path="/penukaran">
                <Penukaran baseContext={this} />
              </Route>

              {/* Product */}
              <Route exact path="/product">
                <Product baseContext={this} />
              </Route>

              {/* Rules */}
              <Route exact path="/rules">
                <Rules baseContext={this} />
              </Route>

              {/* Code Grand Prize */}
              <Route exact path="/u-code-grandprize">
                <CodeGrandPrize baseContext={this} />
              </Route>

              <Route>
                <Redirect to="/dashboard" />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
